<template>
  <router-view/>
</template>

<style lang="scss">
.scrollbar {
    scrollbar-color: #fff transparent;
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 10px;
    }
}
</style>
