import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/artifact/:slug',
    name: 'artifact',
    component: () => import('@/views/artifact.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/404.vue'),
    meta: {
        title: 'Not Found',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
